import React, { useCallback, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import Pusher from "pusher-js";
import toast, { Toaster } from "react-hot-toast";

import "../fonts.css";
import { Box, styled, Divider } from "@mui/material";
// Hooks
import { useCookie } from "../Hooks/useCookie";

// Utils
import { generateName, plainPersistentMessages } from "../utils";
import NetworkManager from "../NetworkManager";
import {
  EVENT_MESSAGE_SENT,
  EVENT_MESSAGE_REACTION,
  messageMaxLength,
  LANGUAGE_CUSTOMER_KEY,
  DEFAULT_LANGUAGE,
  EVENT_CLEAR_TABLE,
} from "../constants";

// Components
import { HeaderNav } from "./Components/HeaderNav";

// Types
import {
  IMessage,
  EMessageType,
  EBottomModalContent,
  EScreenMode,
  IHandshakeResponse,
  IKeyMessage,
  RestaurantLanguage,
} from "../Interfaces/generalInterfaces";
import PrivateChannel from "pusher-js/types/src/core/channels/private_channel";
import ChatContent from "./Components/ChatContent";
import { SendMessages } from "./Components/SendMessage/SendMessages";
import { useCustomerViewAuth } from "../Auth/CustomerViewAuth";

let localChannel: PrivateChannel;
const toastConfig = (success: boolean) => ({
  icon: "",
  style: {
    borderRadius: "30px",
    background: success ? "#2ECC71" : "#222",
    color: "#fff",
    width: "100%",
    paddingLeft: "10px",
  },
});

export const Chat: React.FC = () => {
  const { restaurantUUID, tableUUID, dynamicCode } = useParams();
  const [homeURL, setHomeURL] = useState<string>(null!);
  const [tableId, setTableId] = useState<string>(null!);
  const [restaurantId, setRestaurantId] = useState<string>(null!);

  const [clientName, setClientName] = useCookie("clientName", "");

  const [bottomModal, setBottomModal] = useState<boolean>(false);
  const [bottomModalContent, setBottomModalContent] =
    useState<EBottomModalContent>(null!);
  const [screenMode, setScreenMode] = useState<EScreenMode>(EScreenMode.HOME);
  const [messageList, setMessageList] = useState<IMessage[]>([]);
  const [messageReactions, setMessageReactions] = useState<IKeyMessage>({});

  const [table, setTable] = useState<IHandshakeResponse>(null!);
  const [componentError, setComponentError] = useState<string>(null!);
  const [languageSetting, setLanguageSetting] = useState<RestaurantLanguage>();
  const auth = useCustomerViewAuth();

  useEffect(() => {

    setHomeURL(`/home/${restaurantUUID}/${tableUUID}/${dynamicCode}`);
  
    auth.updateAccountId(restaurantUUID);
    auth.updateResourceId(tableUUID);
    auth.updateChannelId(dynamicCode);

    auth.getAccount(restaurantUUID, tableUUID);
    auth.getBuzzList(restaurantUUID);
  }, []);
  
  useEffect(() => {
    initializePusher();
    // return () => {
    //   if (localChannel) localChannel.unbind();
    // };
  }, []);
  
  useEffect(() => {
    const createRandomName = () => {
      const randomName = generateName();
      const dayInMinutes = 86400000; // One day in milliseconds
      setClientName(randomName, dayInMinutes);
    };
  
    if (!clientName) {
      createRandomName();
    }
  
    // return () => {
    //   if (localChannel) localChannel.unbind();
    // };
  }, [clientName]);

  const initializePusher = async () => {
    const response = await NetworkManager.clientHandshake(
      tableUUID,
      dynamicCode,
      restaurantUUID
    );
    clientHandshake(response);
    if (!restaurantUUID || !tableUUID || !response.payload.channelId) {
      return setComponentError("Error in table identifiers");
    }
    const pusher = new Pusher(window._env_.PUSHER_APPKEY, {
      authorizer: (channel) =>
        NetworkManager.pusherAuthorizer(channel, restaurantUUID, tableUUID),
        cluster: window._env_.PUSHER_CLUSTER,
    });
    localChannel = pusher.subscribe(
      response.payload.channelId
    ) as PrivateChannel;

    localChannel.bind("pusher:subscription_error", (error: any) => {
      toast.error("The connection was closed abnormally.", toastConfig(false));

      if (response.payload.channelId) {
        getPersistentMessages(response.payload.channelId);
      } else {
        toast.error("Unexpected error getting channel id", toastConfig(false));
      }
    });

    localChannel.bind("pusher:subscription_succeeded", () => {
      if (response.payload.channelId) {
        getPersistentMessages(response.payload.channelId);
      } else {
        toast.error("Unexpected error getting channel id", toastConfig(false));
      }

      toast.dismiss();
      toast.success('Connection established', toastConfig(true));
    });

    localChannel.bind(EVENT_MESSAGE_SENT, handleMessageSent);
    localChannel.bind(EVENT_MESSAGE_REACTION, handleMessageReactions);
    localChannel.bind(EVENT_CLEAR_TABLE, handleSessionClear);
  };

  const clientHandshake = async (response: any) => {
    if (!tableUUID || !dynamicCode || !restaurantUUID) {
      return setComponentError("Error in table identifiers ");
    }

    if (!response.success || !response.payload || !response.payload?.tableId) {
      const errorMessage =
        response.message || "Unhandled initializing connection";
      return setComponentError(errorMessage);
    }
    setLanguageSetting(response?.payload.restaurantInfo?.language);
    setTable(response.payload);
    getPersistentMessages(response.payload.channelId);
  };

  const handleMessageSent = useCallback(
    (data: IMessage) => {
      setMessageList((prevState) => [...prevState, data]);
    },
    [messageList]
  );

  const handleMessageReactions = useCallback(
    (data: IMessage) => {
      setMessageReactions((prevState) => ({ ...prevState, [data.id]: data }));
    },
    [messageReactions]
  );
  const handleSessionClear = useCallback((data) => {}, [messageReactions]);

  const handleBottomModalContent = (modalContent: EBottomModalContent) => {
    setBottomModalContent(modalContent);
    setBottomModal(true);
  };

  const closeModal = () => {
    setBottomModal(false);
  };

  const messageTrigger = async (message: string, messageType: EMessageType) => {
    closeModal();
    // handleStScreenMode(EScreenMode.HISTORY);
    const newMessage: IMessage = {
      id: uuidv4(),
      message: message,
      username: clientName,
      timestamp: Date.now(),
      messageType: messageType,
    };
    if (languageSetting?.isTranslate) {
      const msg = messageType === "CHAT" ? message : newMessage.message;
      const { data } = await NetworkManager.translateText(
        msg,
        languageSetting.targetLang
      );
      const originalMessage = newMessage.message;
      newMessage.originalMessage = originalMessage;
      newMessage.message = data;
    }
    const send = await NetworkManager.sendMessageToBackend(
      tableId,
      newMessage,
      table.channelId,
      EVENT_MESSAGE_SENT
    );
    localChannel.trigger(EVENT_MESSAGE_SENT, { ...newMessage });
    setMessageList([...messageList, newMessage]);
  };

  const getPersistentMessages = async (channelId: string) => {
    const response = await NetworkManager.getPersistentMessages(channelId);

    if (!response.success) {
      const errorMessage =
        response.message || "Unhandled error getting persistent messages";
      return console.error(errorMessage);
    }

    const events = response?.payload?.events || [];
    const { messages, reactions } = plainPersistentMessages(events);

    setMessageList(messages);
    setMessageReactions(reactions);
  };

  const handleStScreenMode = (screenMode: EScreenMode) => {
    setScreenMode(screenMode);
  };

  const handleClickSurvey = (messageId: string) => {
    const newMessage: IMessage = {
      id: messageId,
      message: "Ok",
      username: clientName || "",
      timestamp: Date.now(),
      messageType: EMessageType.CONFIRMATION,
    };

    localChannel.trigger(EVENT_MESSAGE_REACTION, { ...newMessage });
    setMessageReactions((prevState) => ({
      ...prevState,
      [newMessage.id]: newMessage,
    }));

    NetworkManager.sendMessageToBackend(
      tableId,
      newMessage,
      table?.channelId,
      EVENT_MESSAGE_REACTION
    );

    // @ts-ignore
    window.open(table?.restaurantInfo?.surveyData?.link, "_blank").focus();
  };
  const ChatWindow = styled(Box)(
    () => `
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          flex: 1;
          backgroundImage: url(${Image}),
          backgroundAttachment: 'fixed',
          backgroundColor: 'rgb(254 238 232 / 37%)',
  `
  );
  return (
    <div
      className="flex flex-col h-screen justify-between"
      style={{ fontFamily: "Heebo" }}
    >
      <Toaster />
      {componentError && (
        <div>
          <h1>Unfortunate error</h1>
          <p>{componentError}</p>
        </div>
      )}
      {!componentError && (
        <>
          <ChatWindow>
            <Box flex={1}>
              <HeaderNav
                setScreenMode={() => handleStScreenMode(EScreenMode.HOME)}
                screenMode={EScreenMode.BACK}
                backURL={homeURL}
                title="Message"
              />
              <ChatContent
                account={auth.clientAccount}
                messages={messageList}
                messageReactions={messageReactions}
              />
              <SendMessages
                messageTrigger={messageTrigger}
                isShowBuzzModal={true}
                showBuzzModal={() =>
                  handleBottomModalContent(EBottomModalContent.BUZZ)
                }
                accountId={restaurantId}
              ></SendMessages>
            </Box>
            <Divider />
          </ChatWindow>
        </>
      )}
    </div>
  );
};
